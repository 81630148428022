<script>
import WalletState from "../state/WalletState";
import { showAccount } from "../utils/utils";
export default {
  name: "MyHeader",
  mounted() {
    WalletState.onStateChanged(this.handleAccountsChanged);
  },
  data() {
    return {
      userCode: '0x0000',
    }
  },
  methods: {
    handleAccountsChanged() {
      const wallet = WalletState.wallet;
      if (wallet.account) {
        this.userCode = showAccount(wallet.account);
      }
    },
    handleShowPopup() {
      this.$emit("showParentPopup", true)
    }
  }
}
</script>

<template>
<header>
  <div class="header">
    <div class="left">
      <img class="logo" src="@/assets/images/logo.png" alt="logo" />
    </div>
    <div class="right">
      <div class="wallet">
        <img src="@/assets/images/bar_1.png" alt="bg">
        {{ userCode }}
      </div>
      <img src="@/assets/images/bar_2.png" alt="menu" class="menu" @click="handleShowPopup">
    </div>
  </div>

</header>
</template>

<style scoped lang="less">
header {
  width: 100%;
  padding: .1rem .15rem;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: 99;
  max-width: 450px;
      background: #1F2128;
  .header {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      border: 0;
    }
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        height: .4rem;
        margin-right: .13rem;
		    display: block;
		    border-radius: 50%;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .wallet {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 .14rem 0 0;
        font-family: Source Han Sans, Source Han Sans,serif;
        font-size: .12rem;
        color: #70001c;
        background: #ffa100;
        height: .3rem;
        border: 1px solid #979797;
        border-radius: .05rem;
        img {
          height: 100%;
          margin-right: .12rem;
        }
      }
      .menu {
        height: .28rem;
        margin-left: .1rem;
      }
    }
  }
}
</style>