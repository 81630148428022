import Vue from 'vue'
import VueRouter from 'vue-router'

let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: ()=> import('../views/HomeView.vue')
  },
  {
    path: '/me',
    name: 'me',
    component: ()=> import('../views/MeView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
